import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../data/store';
import { closeModal } from '../data/stores/modalStore';
import Modal from './common/Modal';
import ScreenBusySpinner from './common/ScreenBusySpinner';
import { styled } from 'styled-components';
import Button from './common/buttons/Button';
import { updateSelf } from '../data/thunks/userThunk';

export default function UserAgreementModal() {
	const { user } = useSelector((state: RootState) => state.authState);

	const open = !!user?.id && !user?.acceptedTermsAndConditions;

	const dispatch = useDispatch<AppDispatch>();

	const handleClose = () => {
		dispatch(closeModal());
	};

	const handleAccept = () => {
		dispatch(
			updateSelf({
				acceptedTermsAndConditions: true,
				acceptedTermsAndConditionsDate: new Date(),
			})
		);
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ScreenBusySpinner />
			<FormContainer>
				<div>Please accept terms and conditions</div>
				<Button onClick={handleAccept}>Accept</Button>
			</FormContainer>
		</Modal>
	);
}

const LinkContainer = styled.div`
	font-size: 12px;
	text-align: left;
	margin-top: 5px;
`;

const FormContainer = styled.div`
	text-align: center;
`;

const InputContainer = styled.div`
	max-width: 450px;
	display: flex;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
`;
